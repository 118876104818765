<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<script setup lang="ts">

useSeoMeta({
  ogImage: `${process.env.NODE_ENV === 'development'
    ? 'http://localhost:3000'
    : 'https://nikola-ivanovic-software-engineer.com'
    }/images/nikola-ivanovic-software-engineer.jpg`,
})

useSchemaOrg([
  definePerson({
    name: 'Nikola Ivanović',
    description: 'Nikola Ivanović is a Full-Software Engineer and SEO & Digital Marketing Specialist with 5 years in the IT industry.',
    image: 'https://nikola-ivanovic-software-engineer.com/images/nikola-ivanovic-software-engineer.jpg',
    jobTitle: 'Full-Stack Sofftware Engineer and SEO & Digital Marketing Specialist',
    knowsAbout: ['Vue.js', 'React.js', 'Nuxt.js', 'Next.js', 'Angular', 'GraphQL', 'TypeScript', 'Node.js', 'Express.js', 'Tailwind CSS', 'Bootstrap', 'SASS', 'MySQL', 'Microsoft SQL Server', 'MongoDB', 'Firebase', 'Jest', 'Vitest', 'Docker', 'Strapi', 'WordPress', 'Java', 'Python'],
    sameAs: [
      'https://github.com/NikolaJohnny94',
      'https://www.linkedin.com/in/nikola-ivanovi%C4%87-2b6a13179/',
      'https://x.com/nikola8794pwd',
      'https://codepen.io/NikolaJohnny',
    ]
  }),
  defineWebSite({ name: 'Nikola Ivanović', url: 'https://nikola-ivanovic-software-engineer.com' }),
])
</script>
