import { default as contact7yxvyM9YigMeta } from "C:/Users/Nikola_Johnny_33/Desktop/Projects/my-portfolio/pages/contact.vue?macro=true";
import { default as indexztjug5HuozMeta } from "C:/Users/Nikola_Johnny_33/Desktop/Projects/my-portfolio/pages/index.vue?macro=true";
import { default as projectsPZdh2f0GewMeta } from "C:/Users/Nikola_Johnny_33/Desktop/Projects/my-portfolio/pages/projects.vue?macro=true";
export default [
  {
    name: contact7yxvyM9YigMeta?.name ?? "contact",
    path: contact7yxvyM9YigMeta?.path ?? "/contact",
    meta: contact7yxvyM9YigMeta || {},
    alias: contact7yxvyM9YigMeta?.alias || [],
    redirect: contact7yxvyM9YigMeta?.redirect,
    component: () => import("C:/Users/Nikola_Johnny_33/Desktop/Projects/my-portfolio/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: indexztjug5HuozMeta?.name ?? "index",
    path: indexztjug5HuozMeta?.path ?? "/",
    meta: indexztjug5HuozMeta || {},
    alias: indexztjug5HuozMeta?.alias || [],
    redirect: indexztjug5HuozMeta?.redirect,
    component: () => import("C:/Users/Nikola_Johnny_33/Desktop/Projects/my-portfolio/pages/index.vue").then(m => m.default || m)
  },
  {
    name: projectsPZdh2f0GewMeta?.name ?? "projects",
    path: projectsPZdh2f0GewMeta?.path ?? "/projects",
    meta: projectsPZdh2f0GewMeta || {},
    alias: projectsPZdh2f0GewMeta?.alias || [],
    redirect: projectsPZdh2f0GewMeta?.redirect,
    component: () => import("C:/Users/Nikola_Johnny_33/Desktop/Projects/my-portfolio/pages/projects.vue").then(m => m.default || m)
  }
]